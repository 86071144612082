import { Col, Space, Table, Tooltip,Input } from 'antd';
import React, { useEffect, useState } from 'react';
import dataApi from "../../api/api";
import getColumnSearchProps from '../Utils/getColumnSearchProps';
import { useNavigate } from "react-router-dom";
import { sortColumns, showDeleteConfirm, onChangeTable } from '../Utils/isFunction';
import { useFetching } from '../../hoc/fetchingHook';
import { EditOutlined, DeleteOutlined, CameraOutlined, EyeOutlined } from "@ant-design/icons";
import './studioTable.css';
// import EventByClient from "./EventBYClient";
import { StudioChat } from "../HelpChat/Chat";
import { v4 as uuidv4 } from 'uuid';

const StudiosTable = ({ studioInfo }) => {
    const navigate = useNavigate();
    const studio_id = localStorage.getItem('studio_id');
    const [data, setData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [newMessagesCount, setNewMessagesCount] = useState('');
    const [searchText, setSearchText] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 20,
            total: 0,
        },
    });

    const [getClients, isClientsLoading] = useFetching(async (studio_id) => {
        const offset = (tableParams.pagination.current - 1) * tableParams.pagination.pageSize;
        const limit = tableParams.pagination.pageSize;
        const { data: res } = await dataApi.getClientListByStudioIdToken(studio_id, offset, limit);
        if (res) {
            setData(res.client_list);
            setFilteredData(res.client_list); // Исходные данные и отфильтрованные одинаковые
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: res.total,
                },
            });
            if (totalItems === 0) {
                setTotalItems(res.total);
            }
        }
    });

    const goGalleryPage = (client_id) => {
        localStorage.setItem('client_id', client_id);
        navigate('/client/gallery_list');
    };

    const goEditClientPage = (client_id) => {
        localStorage.setItem('client_id', client_id);
        localStorage.setItem('current_user_id', client_id);
        navigate('/studio/edit_client');
        window.location.reload();
    };

    useEffect(() => {
        getClients(studio_id);
    }, [tableParams.pagination.current, tableParams.pagination.pageSize]);

    const handleTableChange = (pagination) => {
        setTableParams({
            pagination: {
                ...pagination,
                total: tableParams.pagination.total,
            },
        });
    };

    const clientsDelete = (id) => {
        showDeleteConfirm(id, 'Client').then((result) => {
            if (result) {
                const updatedData = data.filter((item) => item.id !== id);
                setData(updatedData);
            }
        });
    };
    const handleSearch = (value) => {
        setSearchText(value);
        if (!value) {
            setFilteredData(data);
            return;
        }

        const filtered = data.filter((item) => {
            return Object.values(item).some((field) =>
                String(field).toLowerCase().includes(value.toLowerCase())
            );
        });

        setFilteredData(filtered);
    };

    useEffect(() => {
        const storedMessages = JSON.parse(localStorage.getItem('newClientMessagesCount')) || {};
        setNewMessagesCount(storedMessages);
    }, []);

    const columns = [
        {
            title: 'Client Page',
            dataIndex: 'event_logo',
            key: "event_logo",
            render: (_, record) => (
                <Space wrap>
                    <CameraOutlined className="my_ant_icons_table" onClick={() => goGalleryPage(record.id)} />
                </Space>
            ),
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
            className: 'table-id',
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'name',
            render: (_, record) => (
                <Space direction="vertical">
                    <span>{record.firstName} {record.lastName}</span>
                </Space>
            ),
            className: 'table-name',
            sorter: (a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: sortColumns("email"),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            sorter: sortColumns("phone"),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Total Galleries',
            dataIndex: 'total_events',
            key: 'total_events',
            sorter: (a, b) => a.total_events - b.total_events,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Event Name',
            dataIndex: 'events',
            key: 'events',
            render: (_, record) => (
                <Space direction="vertical">
                    {record.events.map(event => (
                        <Tooltip title={`Event ID: ${event.id}`} key={uuidv4()}>
                            <div style={{width:'150px', borderBottom: '1px solid #6d6868' }}>{event.name}</div>
                        </Tooltip>
                    ))}
                </Space>
            ),
        },
        {
            title: 'Total Images',
            dataIndex: 'total_images',
            key: 'total_images',
            sorter: (a, b) => a.total_images - b.total_images,
        },
        {
            title: 'Total Photographers',
            dataIndex: 'total_photographers',
            key: 'total_photographers',
            sorter: (a, b) => a.total_photographers - b.total_photographers,
        },
        {
            title: 'Photographers',
            dataIndex: 'photographers',
            key: 'photographers',
            render: (_, record) => (
                <Space direction="vertical">
                    {record.photographers.map(photographer => (
                        <Tooltip title={`${photographer.firstName} ${photographer.lastName} (ID-${photographer.photographerID})`} key={uuidv4()}>
                            <div >{`${photographer.firstName} ${photographer.lastName} (ID-${photographer.photographerID})`}</div>
                            <div >{`Original Files: ${photographer.javaOriginalTotal}`}</div>
                            <div >{`Client View: ${photographer.javaModifiedTotal}`}</div>
                            <div >{`Modified: 0`}</div>
                            <div >{`Removed: 0`}</div>
                            <div style={{width:'150px', borderBottom: '1px solid #6d6868' }}>{`Other: ${photographer.javaOtherTotal}`}</div>
                        </Tooltip>
                    ))}
                </Space>
            ),
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            align: 'center',
            sorter: (a, b) => {
                const countA = newMessagesCount[a.id] || 0;
                const countB = newMessagesCount[b.id] || 0;
                return countA - countB;
            },
            render: (_, record) => (
                <Space wrap>
                    <StudioChat studioInfo={studioInfo} clientID={record.id} record={record} />
                    {Object.keys(newMessagesCount).includes(record.id.toString()) && newMessagesCount[record.id] >= 1 &&
                        <span style={{ color: 'red', top: '10px', position: 'absolute' }}>
                            {newMessagesCount[record.id]}
                        </span>}
                </Space>
            ),
        },
        {
            title: 'Edit',
            dataIndex: 'studio_login',
            key: 'studio_login',
            render: (_, record) => (
                <Space wrap>
                    <EditOutlined className="my_ant_icons_table" onClick={() => goEditClientPage(record.id)} />
                </Space>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'studio_delete',
            key: 'studio_delete',
            render: (_, record) => (
                <Space wrap>
                    <DeleteOutlined className="my_ant_icons_table" onClick={() => clientsDelete(record.id)} />
                </Space>
            ),
        },
    ];

    if (isClientsLoading) {
        return (
            <Table
                className="table-striped-rows studio-table-rows"
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={[]}
                loading={isClientsLoading}
                pagination={tableParams.pagination}
            />
        );
    } else {
        return (
            <div>
            <Input.Search
                placeholder="Search..."
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ marginBottom: 16, width: '300px' }}
                allowClear
            />

            <Table
                className="table-striped-rows studio-table-rows"
                pagination={{
                    ...tableParams.pagination,
                    showSizeChanger: true,
                }}
                loading={isClientsLoading}
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={filteredData}
                onChange={handleTableChange}
                scroll={{ x: 100 }}
                summary={() => (
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={100}>
                            <b>All Clients: {data.length}</b>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                )}
            />
            </div>
        );
    }
};

export default StudiosTable;

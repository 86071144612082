import {
    CalendarOutlined,
    CameraOutlined,
    HomeOutlined,
    UsergroupAddOutlined,
    PlusCircleOutlined,
    UnorderedListOutlined,
    DollarCircleOutlined,
    FileZipOutlined,
    FolderOutlined,
    FileDoneOutlined,
    SettingOutlined,
    EnvironmentOutlined,
    FileImageOutlined,
    CreditCardOutlined,
    PayCircleOutlined, QuestionCircleOutlined
} from "@ant-design/icons";

export const studioItems = [
    {
        key: 'dashboard',
        icon: <HomeOutlined />,
        label: 'Dashboard',
    },{
        key: 'settings',
        icon: <SettingOutlined />,
        label: 'Settings',
        children: [
            {
                key: 'terms_conditions',
                label: 'Terms & Conditions',
            },
        ]
    },
    {
        key: 'studio_clients',
        icon: <UsergroupAddOutlined/>,
        label: 'Clients',
        children: [
            {
                key: 'add_client',
                label: 'Add Clients',
                icon: <PlusCircleOutlined />,

            },
            {
                key: 'list_client',
                label: 'Clients List ',
                icon: <UnorderedListOutlined />,
            },
        ]
    },
    {
        key: 'payment_credits',
        icon: <PayCircleOutlined />,
        label: 'Payments & Credits',
        children: [
            {
                key: 'client_credit_add',
                label: 'Add Payments & Credits',
                icon: <PlusCircleOutlined />,
            },{
                key: 'client_credit_list',
                label: 'Payments & Credits List',
                icon: <UnorderedListOutlined />,
            },
        ],
    },


    {
        key: 'studio_events',
        icon: <CalendarOutlined />,
        label: 'Events',
        children: [
            {
                key: 'add_event',
                label: 'Add Events',
                 icon: <PlusCircleOutlined />,
            },
            {
                key: 'list_event',
                label: 'Events List',
                icon: <UnorderedListOutlined />,
            },
        ]
    },

    {
        key: 'studio_ph',
        icon: <CameraOutlined />,
        label: 'Photographers',
        children: [
            {
                key: 'add_ph',
                label: 'Add Photographers',
                 icon: <PlusCircleOutlined />,
            },
            {
                key: 'list_ph',
                label: 'Photographers List',
                icon: <UnorderedListOutlined />,
            },
        ]
    },

    {
        key: 'sub2',
        icon: <UsergroupAddOutlined />,
        label: 'Staff',
        children: [
            {
                key: 'staff_add',
                label: 'Add Staff',
                icon: <PlusCircleOutlined />,
            },
            {
                key: 'staff_list',
                label: 'Staff List',
                icon: <UnorderedListOutlined />,
            },
        ],
    },
    {
        key: 'subs',
        icon: <EnvironmentOutlined  />,
        label: 'Venue',
        children: [
            {
                key: 'venue_add',
                label: 'Add Venue',
                icon: <PlusCircleOutlined />,
            },
            {
                key: 'venue_list',
                label: 'Venue List',
                icon: <UnorderedListOutlined />,
            },
        ],
    },
    {
        key: 'backgrounds',
        icon: <FileImageOutlined />,
        label: 'Backgrounds',
        children: [
            // {
            //     key: 'background_add',
            //     label: 'Upload New',
            // },
            {
                key: 'background_create_group',
                label: 'Create Group',
            },
            {
                key: 'background_group_list',
                label: 'Group List',
            },
        ]
    },
    {
        key: 'order_list',
        icon: <UnorderedListOutlined />,
        label: 'Order List',
    },
    {
        key: 'pricelist',
        icon: <DollarCircleOutlined />,
        label: 'Price List',
        children: [
            {
                key: 'price1',
                label: 'Prints',
                children: [
                    {
                        key: 'price_add',
                        label: 'Add Prints',
                        icon: <PlusCircleOutlined />,
                    },
                    {
                        key: 'price_list',
                        label: 'Prints List',
                        icon: <UnorderedListOutlined />,
                    },
                    {
                        key: 'print_helper_video',
                        label: 'How to use',
                        icon: <QuestionCircleOutlined />,
                    },
                ]
            },
            {
                key: 'cards',
                label: 'Cards',
                children: [
                    {
                        key: 'card1',
                        label: 'Flat Cards',
                        children: [
                            {
                                key: 'flat_cards_add',
                                label: 'Add Flat Cards',
                                icon: <PlusCircleOutlined />,
                            },
                            {
                                key: 'flat_cards_list',
                                label: 'Flat Cards List',
                                icon: <UnorderedListOutlined />,
                            },
                        ]
                    },
                    {
                        key: 'card3',
                        label: 'Fold Over',
                        children: [
                            {
                                key: 'fold_over_add',
                                label: 'Add Fold Over',
                                icon: <PlusCircleOutlined />,
                            },
                            {
                                key: 'fold_over_list',
                                label: 'Fold Over List',
                                icon: <UnorderedListOutlined />,
                            },
                        ]
                    },
                    {
                        key: 'card4',
                        label: 'Photo Cards',
                        children: [
                            {
                                key: 'photo_cards_add',
                                label: 'Add Photo Cards',
                                icon: <PlusCircleOutlined />,
                            },
                            {
                                key: 'photo_cards_list',
                                label: 'Photo Cards List',
                                icon: <UnorderedListOutlined />,
                            },
                        ]
                    },

                ]
            },
            {
                key: 'album2',
                label: 'Albums',
                children: [
                    {
                        key: 'albums_add',
                        label: 'Add Albums',
                        icon: <PlusCircleOutlined />,
                    },{
                        key: 'albums_list',
                        label: 'Albums List',
                        icon: <UnorderedListOutlined />,
                    },
                ]
            },
            {
                key: 'units',
                label: 'Units',
                children: [
                    {
                        key: 'units_add',
                        label: 'Add Units',
                        icon: <PlusCircleOutlined />,
                    },{
                        key: 'units_list',
                        label: 'Units List',
                        icon: <UnorderedListOutlined />,
                    },
                ]
            },
        ]
    },
    {
        key: 'sub5',
        icon: <FileDoneOutlined />,
        label: 'Contract',
        children: [
            {
                key: 'contract',
                label: 'Add Contract',
                icon: <PlusCircleOutlined />,
            },
            {
                key: 'contract_list',
                label: 'Contract List',
                icon: <UnorderedListOutlined />,
            },
        ]
    },
    {
        key: 'Package',
        icon: <FolderOutlined />,
        label: 'Package',
        children: [
            {
                key: 'add_package',
                label: 'Add Package',
                icon: <PlusCircleOutlined />,
            },
            {
                key: 'list_package',
                label: 'Package List',
                icon: <UnorderedListOutlined />,

            },
        ]
    },
    {
        key: 'monitoring',
        icon: <FileZipOutlined />,
        label: 'Monitoring',
        children:[
            {
                key: 'zip',
                icon: <FileZipOutlined/>,
                label: 'ZIP',
                children: [
                    {
                        key: 'completed',
                        label: 'Completed',
                    },
                    {
                        key: 'in_process',
                        label: 'In Process',
                    }
                ]
            }
        ]
    },
];
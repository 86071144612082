import React from "react";
import styled from "styled-components";

const VideoGrid = ({ videos }) => {
    return (
        <GridContainer>
            {videos.map((videoUrl, index) => (
                <VideoWrapper key={index}>
                    <video controls muted>
                        <source src={videoUrl} type="video/mp4" />
                        Your browser not support video player.
                    </video>
                </VideoWrapper>
            ))}
        </GridContainer>
    );
};


const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 16px;
  padding: 16px;
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;

  video {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

export default VideoGrid;

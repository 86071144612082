import VideoGrid from "../../../Studios/VideoGrid/VideoGrid";

const PrintHelper = () => {
    const videoUrls = [
        "https://media.w3.org/2010/05/sintel/trailer.mp4",
        "https://media.w3.org/2010/05/sintel/trailer.mp4",
        "https://media.w3.org/2010/05/sintel/trailer.mp4",
        "https://media.w3.org/2010/05/sintel/trailer.mp4",
    ];

    return (
        <div>
            <h1>How to add price list for print products</h1>
            <VideoGrid videos={videoUrls} />
        </div>
    );
};

export default PrintHelper;